.skills {
    height: 100%;
    background: var(--cyan-theme-background-color);
    background-size: cover;
    background-repeat: no-repeat;

    &__content-wrapper {
        display: flex;
        padding: 20px;
        gap: 20px;


        &__inner-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__category {
                display: inline-block;
                color: var(--cyan-theme-main-color);
                font-size: 22px;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 0.5px;
                position: relative;
                text-transform: uppercase;
                padding: 5px 10px;
                border-bottom: 2px solid var(--cyan-theme-main-color);
            }

            &__category::before,
            &__category::after {
                position: absolute;
                content: '';
                width: 2px;
                height: 4px;
                background-color: var(--cyan-theme-main-color);
                bottom: -1px;
            }

            &__category::before {
                left: 0;
            }

            &__category::after {
                right: 0;
            }

            &__progressbar-container {
                width: 100%;
            }

            .progressbar-wrapper {
                padding: 30px 0px 0px 0px;

                p {
                    font-size: 1.6rem;
                    margin: 0 0 10px;
                    font-weight: 750;
                    text-transform: uppercase;
                    color: var(--cyan-theme-main-color);;

                    &__category::before,
                    &__category::after {
                        position: absolute;
                        content: '';
                        width: 2px;
                        height: 5px;
                        background-color: var(--cyan-theme-main-color);
                        bottom: -1px;

                    }

                    &__category::before {
                        left: 0;
                    }

                    &__category::after {
                        right: 0;
                    }

                    &__progressbar-container {
                        width: 100%;
                    }

                    .progressbar-wrapper {
                        padding: 50px 0px 0px 0px;

                        p {
                            font-size: 1.6rem;
                            margin: 0 0 10px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: var(--cyan-theme-sub-text-color);
                        }
                    }

                }
            }
        }
    }

    &__content-wrapper__text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px;
        gap: 20px;
        justify-content: center;
        align-items: center;

        &__inner-content {
            color: var(--cyan-theme-sub-text-color);
            justify-content: center;
            align-items: center;

            &__category-text {

                h3 {
                    text-align: center;
                    font-size: 2.5rem;
                    color: var(--cyan-theme-main-color);
                }

                p {
                    line-height: 1.5cm;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media only screen and (max-width : 768px) {
    .skills {
        &__content-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        &__category {
            width: 40%;
            padding: 0 10px;
            box-sizing: border-box;
            break-inside: avoid-column;
            column-count: 2;
            column-gap: 0;
        }
    }
}